<template>
  <ModalMainNew
    show-close-mobile
    modal-header-icon="fairness"
    :show-header-icon="activeView !== 'reciept'"
    :modal-header="modalHeader"
    @close="close"
  >
    <section
      :class="{ 'pt-2' : activeView === 'reciept'}"
    >
      <template v-if="activeView === 'reciept'">
        <InstantGameReceipt
          :seed-data="rawSeedData"
          :game-fairness-data="gameFairnessData"
          :currency="currency"
          :multiplier="multiplier"
        />
        <div
          class="pb-5 text-xs text-center text-slate-light font-semibold"
        >
          <button
            class="pb-2"
            type="button"
            @click.prevent.stop="showSeed"
          >
            {{ status === 'revealed' ? 'Verify now' : 'Rotate your seed pair in order to verify this bet' }}
          </button>
        </div>
      </template>
      <InstantGameSeed
        v-else-if="activeView === 'seed'"
        :seed-data="rawSeedData"
        :active-sub-tab="subTab"
        :has-unfinished-games="hasUnfinishedGames"
        :mines="mines"
        :rows="rows"
        :risk="risk"
        @update-client-seed="handleUpdateClientSeed"
        @rotate-seed="handleRotateSeed"
      />
    </section>
  </ModalMainNew>
</template>

<script setup>
import { useTequityGames } from '@/composables/tequityGames/useTequityGames';

const gameStore = useGamesStore();
const { gameFairnessData, } = storeToRefs(gameStore);

const uiStore = useUiStore();
const { showFairnessModal, toastNotification, } = storeToRefs(uiStore);
const { getActiveRngSeeds, roundRngState, updateClientSeed, generateNewClientSeed, getRoundData, getWinMultiplier, } = useTequityGames();

const multiplier = ref(0);
const mines = ref(1);
const rows = ref(8);
const risk = ref('low');

const target = ref(null);
const result = ref(null);
const currency = ref('');
const rawSeedData = ref({});
const activeSeedData = ref(null);
const activeView = ref('reciept');
const subTab = ref('seed');

const clientSeed = computed(() => rawSeedData.value?.clientSeed);

const modalHeader = computed(() => activeView.value === 'reciept' ? 'Bet' : 'Fairness');
const status = computed(() => rawSeedData.value?.status ?? null);
const hasUnfinishedGames = computed(() => activeSeedData.value?.unfinishedGames.length > 0);

async function getInitialData() {
  try {
    const [
      responseRoundRngState,
      responseActiveSeeds,
      responseRoundData,
    ] = await Promise.allSettled([
      roundRngState(),
      getActiveRngSeeds(),
      getRoundData(),
    ]);
    rawSeedData.value = responseRoundRngState.value;
    activeSeedData.value = responseActiveSeeds.value;
    const wagers = responseRoundData.value.round.wagers[0];
    multiplier.value = getWinMultiplier(responseRoundData.value, gameFairnessData.value.name, wagers.params.rows, wagers.params.risk) ?? 0;
    mines.value = wagers.data.numberOfMines ?? 1;
    rows.value = wagers.data.rows ?? 8;
    risk.value = wagers.data.risk ?? 'low';
    target.value = wagers.data.wonMultiplier ?? 0;
    result.value = wagers.data.wonMultiplier ?? 0;
    currency.value = responseRoundData.value?.currency ?? 'Unknown currency';
  } catch (err) {
    toastNotification.value = {
      type: 'warning',
      title: 'Something went wrong',
      content: 'Please try again later',
      closeAfter: 3000,
    };
    close();
  }
}

async function handleUpdateClientSeed(clientId) {
  if (hasUnfinishedGames.value) {
    toastNotification.value = {
      type: 'warning',
      title: 'Finish your games',
      content: 'You need to finish all your games before you can rotate your seed',
      closeAfter: 3000,
    };
    return;
  }
  const newClientSeed = clientId.length && clientId !== clientSeed.value ? clientId : generateNewClientSeed();
  const response = await updateClientSeed(newClientSeed);
  if (response) {
    rawSeedData.value = response;
  }
}

async function handleRotateSeed() {
  if (hasUnfinishedGames.value) {
    toastNotification.value = {
      type: 'warning',
      title: 'Finish your games',
      content: 'You need to finish all your games before you can rotate your seed',
      closeAfter: 3000,
    };
    return;
  }
  const response = await updateClientSeed(clientSeed.value);

  if (response) {
    rawSeedData.value = response;
  }
}

function showSeed() {
  // TODO: use enums with typescript
  subTab.value = status.value === 'revealed' ? 'verify' : 'seed';
  activeView.value = 'seed';
}

function close() {
  showFairnessModal.value = false;
}

await getInitialData();
</script>
