<template>
  <div class="mb-2">
    <label
      v-if="label.length"
      class="mb-1 font-semibold text-xs block"
    >
      {{ label }}
    </label>
    <slot v-if="isSlot"/>
    <div v-else class="flex items-center h-11 bg-slate-600 rounded-lg font-bold">
      <p v-if="readonly" class="text-xs px-4 py-2 bg-slate-600 rounded-lg" :class="[{ 'truncate': truncate }, copyable ? 'w-11/12 pr-0' : 'w-full']">
        {{ modelValue }}
      </p>
      <input
        v-else
        v-model="modelValue"
        :readonly="readonly"
        :type="type"
        class="grow text-xs px-4 py-2 bg-slate-600 border-none rounded-lg h-full"
      >
      <button
        v-if="copyable"
        class="w-1/12 text-xl text-slate-light icon-ico-copy right-5 text-center pr-1 cursor-pointer active:text-[1.15rem]"
        @click.prevent.stop="copyToClipboard"
      />
    </div>
  </div>
</template>

<script setup>
import { useClipboard } from '@vueuse/core';

defineProps({
  label: {
    type: String,
    required: true,
  },
  copyable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  truncate: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'text',
  },
});

const isSlot = !!useSlots().default;
const modelValue = defineModel('modelValue');

function copyToClipboard() {
  const { copy, } = useClipboard({ source: modelValue.value, legacy: true, });
  copy();
}
</script>
