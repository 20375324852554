<template>
  <section class="pb-5">
    <div class="w-full inline-flex justify-center lg:justify-start bg-slate-900 p-1 mb-2 rounded-lg mt-4">
      <TabMain
        v-for="tab in tabs"
        :key="tab.id"
        v-bind="tab"
        :text="tab.text"
        :active="activeTab === tab.id"
        size="small"
        lightly-rounded
        class="w-6/12"
        @click.prevent.stop="activeTab = tab.id"
      />
    </div>
    <InstantGameChangeGameSeed
      v-if="activeTab === 'seed'"
      :seed-data="seedData"
      @update-client-seed="emit('update-client-seed', $event)"
      @rotate-seed="emit('rotate-seed')"
    />
    <InstantGameVerify
      v-else
      :seed-data="seedData"
      :mines="mines"
      :rows="rows"
      :risk="risk"
    />
  </section>
</template>

<script setup>
const props = defineProps({
  seedData: {
    type: Object,
    required: true,
  },
  activeSubTab: {
    type: String,
    required: true,
    validator: value => ['seed', 'verify',].includes(value),
  },
  mines: {
    type: Number,
    required: true,
  },
  rows: {
    type: Number,
    required: true,
  },
  risk: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update-client-seed', 'rotate-seed',]);

const activeTab = ref('seed');
const tabs = [
  {
    id: 'seed',
    text: 'Seed',
  },
  {
    id: 'verify',
    text: 'Verify',
  },
];

onBeforeMount(() => {
  activeTab.value = props.activeSubTab;
});

</script>
