<template>
  <section>
    <div class="text-xs py-3">
      <FormsFieldWithLabel
        v-for="(data, index) in seedDataFormatted"
        :key="`${data?.name}-${index}`"
        :label="data.title"
        :model-value="data.text"
        :copyable="data.showCopy"
        readonly
        :type="data.type"
      />
    </div>
    <p class="text-xs text-center text-slate-light font-semibold mb-3 cursor-pointer" @click="emit('rotate-seed')">
      Rotate Seed Pair
    </p>
    <FormsFieldWithLabel label="New Client Seed">
      <div class="flex items-center h-11 bg-slate-600 rounded-lg font-bold mb-3">
        <input
          v-model="clientSeed"
          class="w-8/12 text-xs px-4 py-2 bg-inherit hover:border-none focus:shadow-none focus:border-none focus:ring-0"
          placeholder="Enter new client seed"
        >
        <button
          class="w-4/12  h-full bg-green-bright text-center text-black rounded-r-lg"
          type="button"
          @click="emit('update-client-seed', clientSeed)"
        >
          Change
        </button>
      </div>
    </FormsFieldWithLabel>
    <FormsFieldWithLabel
      label="Next Server Seed(Hashed)"
      :model-value="seedData.nextServerSeedHash"
      copyable
      readonly
    />
  </section>
</template>

<script setup>
const props = defineProps({
  seedData: {
    type: Object,
    required: true,
  },
});

const clientSeed = ref();

const emit = defineEmits(['update-client-seed', 'rotate-seed',]);

const seedDataFormatted = computed(() => {
  return [
    {
      title: 'Active Client Seed',
      text: props.seedData?.clientSeed,
      showCopy: true,
      type: 'text',
    },
    {
      title: 'Active Server Seed (Hashed)',
      text: props.seedData?.serverSeedHash,
      showCopy: true,
      type: 'text',
    },
    {
      title: 'Nounce',
      text: props.seedData?.nonce, // need to get this data
      showCopy: false,
      type: 'number',
    },
  ];
});

watch(() => props.seedData, (newVal) => {
  clientSeed.value = newVal?.clientSeed || '';
}, { immediate: true, });
</script>
