<template>
  <section class="flex flex-col">
    <div class="flex justify-center items-center border-dotted border-2 border-[#3e3e3f] rounded-lg min-h-[200px] p-4 mb-2">
      <transition name="fade" mode="out-in">
        <Spinner v-if="isLoading" class="w-full"/>
        <p v-else-if="!gameResult">Correct inputs are required to verify results</p>
        <InstantGameResultLimbo
          v-else-if="game.id === 'limbo'"
          :result="gameResult"
        />
        <InstantGameResultDice
          v-else-if="game.id === 'dice'"
          :result="gameResult"
        />
        <InstantGameResultMines
          v-else-if="game.id === 'mines'"
          :result="gameResult"
          :mines="mines"
        />
        <InstantGameResultPlinko
          v-else-if="game.id === 'plinko'"
          :result="gameResult"
          :risk="plinkoRisk"
          :rows="plinkoRows"
        />
        <div v-else>
          <p>No game result</p>
        </div>
      </transition>
    </div>
    <FormsFieldWithLabel label="Game">
      <select
        id="game"
        v-model="game"
        class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
        @change="verifyFairness"
      >
        <option
          v-for="gameOption in activeGames"
          :key="gameOption.id"
          :value="gameOption"
        >
          {{ gameOption.name }}
        </option>
      </select>
    </FormsFieldWithLabel>
    <FormsFieldWithLabel
      v-model="clientSeed"
      label="Client Seed"
      type="text"
      @update:model-value="debounceVerifyFairness"
    />
    <FormsFieldWithLabel
      v-model="serverSeed"
      label="Server Seed"
      type="text"
      @update:model-value="debounceVerifyFairness"
    />
    <FormsFieldWithLabel label="Nonce">
      <div class="flex flex-row bg-slate-600 rounded-lg">
        <input
          id="nonce"
          v-model="nonce"
          class="w-11/12 text-xs border-0 py-2 pb-2.5 h-11 rounded-lg bg-slate-600"
          type="number"
          @input="debounceVerifyFairness"
        >
        <button
          class="w-14 h-11 mr-0.5 bg-slate-900"
          type="button"
          @click.prevent.stop="handleBetAmount('down')"
        >
          <SvgIcon
            icon="arrow-down"
            width="24"
            height="24"
            class="my-auto"
          />
        </button>
        <button
          class="w-14 h-11 bg-slate-900 rounded-r-lg"
          type="button"
          @click.prevent.stop="handleBetAmount('up')"
        >
        <SvgIcon
          icon="arrow-down"
          width="24"
          height="24"
          class="my-auto rotate-180"
        />
        </button>
      </div>
    </FormsFieldWithLabel>
    <FormsFieldWithLabel
      v-if="game.id === 'mines'"
      label="Mines"
    >
      <select
        id="mines"
        v-model="minesNumber"
        class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
      >
        <option v-for="n in 24" :key="n" :value="n">{{ n }}</option>
      </select>
    </FormsFieldWithLabel>
    <template v-if="game.id === 'plinko'">
      <FormsFieldWithLabel label="Risk">
        <select
          id="risk"
          v-model="plinkoRisk"
          class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
        >
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>
      </FormsFieldWithLabel>
      <FormsFieldWithLabel label="Rows">
        <select
          id="rows"
          v-model="plinkoRows"
          class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
        >
          <option v-for="n in [8,9,10,11,12,13,14,15,16]" :key="n" :value="n">{{ n }}</option>
        </select>
      </FormsFieldWithLabel>
    </template>
  </section>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core';
import { useTequityGames } from '@/composables/tequityGames/useTequityGames';

const { activeGames, proveFairness, } = useTequityGames();
const gameStore = useGamesStore();
const { gameFairnessData, } = storeToRefs(gameStore);

const props = defineProps({
  seedData: {
    type: Object,
    required: true,
  },
  mines: {
    type: Number,
    required: true,
  },
  rows: {
    type: Number,
    required: true,
  },
  risk: {
    type: String,
    required: true,
  },
});

const isLoading = ref(false);
const clientSeed = ref('');
const serverSeed = ref('');
const nonce = ref(0);
const game = ref();
const gameResult = ref(null);
const minesNumber = ref(1);
const plinkoRisk = ref('low');
const plinkoRows = ref(8);

function initialGame() {
  const gameFairnessName = gameFairnessData.value?.name;
  const firstGame = activeGames.value[0];
  game.value = gameFairnessName ? activeGames.value.find(game => game.name.toLowerCase() === gameFairnessName.toLowerCase()) || firstGame : firstGame;
}

async function verifyFairness() {
  try {
    isLoading.value = true;
    const response = await proveFairness({ game: game.value.id, serverSeed: serverSeed.value, clientSeed: clientSeed.value, nonce: nonce.value, });
    gameResult.value = response;
  } finally {
    isLoading.value = false;
  }
}

const debounceVerifyFairness = useDebounceFn(verifyFairness, 250, { maxWait: 2000, });

function handleBetAmount(type) {
  if (type === 'up') {
    nonce.value++;
  } else if (type === 'down') {
    nonce.value--;
  }
  debounceVerifyFairness();
}

onMounted(() => {
  clientSeed.value = props.seedData.clientSeed || '';
  serverSeed.value = props.seedData.serverSeed || '';
  nonce.value = props.seedData.nonce || 0;
  minesNumber.value = props.mines;
  plinkoRisk.value = props.risk;
  plinkoRows.value = props.rows;
  initialGame();
  verifyFairness();
});
</script>
