<template>
  <div class="flex flex-col items-center justify-center">
    <p class="absolute text-4xl text-[#0ea00e] drop-shadow-md">{{ gameResult }}</p>
    <SvgIcon
      icon="dice-fairness"
      width="100"
      height="110"
    />
  </div>
</template>

<script setup>
import { useTequityGames } from '@/composables/tequityGames/useTequityGames';

const { diceResult, } = useTequityGames();

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const gameResult = computed(() => diceResult(props.result));
</script>
