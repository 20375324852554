import { gameList, winMultipliersInFairness, plinkoBaseColors } from './tequityData';

export const useTequityGames = () => {
  const { $rollbar, $api, } = useNuxtApp();
  const gamesStore = useGamesStore();
  const {
    gameServerUrl,
    gameFairnessData,
  } = storeToRefs(gamesStore);
  const activeGames = computed(() => gameList.filter(game => game.isActive));

  function getWinMultiplier(response, gameId, rows = 8, risk = 'low') {
    const game = gameList.find(game => game.id === gameId.toLowerCase());
    if (!game) {
      return null;
    }
    switch (gameId.toLowerCase()) {
      case 'mines': {
        const lastWagerIndex = response.round.wagers.length - 1;
        const penultimateWagerIndex = lastWagerIndex - 1 < 1 ? 0 : lastWagerIndex - 1;
        const lastWager = response.round.wagers[lastWagerIndex];
        const lastWagerMultiplier = +Number(lastWager.data.multiplier).toFixed(2);
        const penultimateWagerMultiplier = +Number(response.round.wagers[penultimateWagerIndex].data.multiplier).toFixed(2);
        return lastWagerMultiplier >= 1 ? lastWagerMultiplier : penultimateWagerMultiplier;
      }
      case 'dice':
        return +response.round.wagers[0].data.winMultiplier;
      case 'limbo':
        return +response.round.wagers[0].params.multiplier;
      case 'plinko':
        return +response.config.winMultipliers[rows][risk][response.round.wagers[0].data.multiplierIndex];
      default:
        return null;
    }
  }

  function generateNewClientSeed() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const length = Math.floor(Math.random() * 3) + 8; // Random length between 8 and 10
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  async function getRoundData() {
    try {
      if (!gameServerUrl.value || !gameFairnessData.value.roundId) {
        throw new Error('Game server URL or round ID is not set');
      }
      const response = await $api(`${gameServerUrl.value}/game/replay`, {
        method: 'GET',
        params: {
          roundId: gameFairnessData.value.roundId,
        },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to fetch round data', err);
      throw err;
    }
  }

  async function getActiveRngSeeds() {
    try {
      const response = await window.mwgame.getActiveRngSeeds();
      return response;
    } catch (err) {
      $rollbar.error('Failed to get ActiveRngSeeds', err);
      throw err;
    }
  }

  async function roundRngState() {
    try {
      if (!gameServerUrl.value || !gameFairnessData.value.roundId) {
        throw new Error('Game server URL or round ID is not set');
      }
      const response = await $api(`${gameServerUrl.value}/fairness/roundRngState`, {
        method: 'GET',
        params: {
          roundId: gameFairnessData.value.roundId,
        },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to fetch roundRngState', err);
      throw err;
    }
  }

  async function updateClientSeed(clientId) {
    try {
      const response = await window.mwgame.updateClientSeed(clientId);
      return response;
    } catch (err) {
      $rollbar.error('Failed to update client seed', err);
      throw err;
    }
  }

  async function proveFairness(payload) {
    if (!gameServerUrl.value) {
      throw new Error('Game server URL is not set');
    }
    try {
      const response = await $api(`${gameServerUrl.value}/game/proveFairness`, {
        method: 'GET',
        params: { provider: 'tequity', ...payload, },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to prove fairness', err);
      throw err;
    }
  }

  function limboResult(result) {
    const lastRnIndex = result.randomizations.length - 1;
    const gameData = result.randomizations[lastRnIndex];
    return gameData.gameEvent.result;
  }

  function diceResult(result) {
    const gameData = result.randomizations[0];
    return gameData.gameEvent.roll;
  }

  function minesResult(result, mines) {
    const lastRnIndex = result.randomizations.length - 1;
    const gameData = result.randomizations[lastRnIndex];

    const getMinesPosition = (gameData) => {
      const minesPosition = gameData.gameEvent.minesPositionsBuffer;

      if (!minesPosition) {
        return [];
      } else {
        return minesPosition.slice(0, mines);
      }
    };

    const minesPosition = getMinesPosition(gameData);
    return minesPosition.sort((a, b) => a - b);
  }

  function generatePlinkoColors(length) {
    const baseLength = plinkoBaseColors.length;
    const factor = (baseLength - 1) / (length - 1);

    const colors = Array.from({ length, }, (_, i) => {
      const baseIndex = Math.round(i * factor);
      return plinkoBaseColors[baseIndex];
    });

    return colors;
  }

  function plinkoResult(result, risk, rows) {
    const gameData = result.randomizations[rows - 1];
    if (!gameData) {
      return null;
    }
    const multiplierIndex = gameData.gameEvent.multiplierIndex;
    const gameResult = winMultipliersInFairness[rows][risk.toLowerCase()][multiplierIndex];
    const color = generatePlinkoColors(rows + 1)[multiplierIndex];
    return { gameResult, color, };
  }

  return {
    getActiveRngSeeds,
    roundRngState,
    updateClientSeed,
    generateNewClientSeed,
    getRoundData,
    proveFairness,
    activeGames,
    limboResult,
    diceResult,
    minesResult,
    getWinMultiplier,
    plinkoResult,
  };
};
