<template>
  <div class="mines-container grid grid-cols-5 gap-2">
    <div v-for="i in 25" :key="i" class="mines-cell w-[51px] h-[45px] flex items-center justify-center rounded-lg bg-[rgb(33,55,67)] shadow-md">
      <img
        v-if="gameResult.includes(i - 1)"
        :src="bombUrl"
        alt="bomb"
        class="h-full"
      >
      <img
        v-else
        :src="gemUrl"
        alt="mine"
        class="h-full"
      >
    </div>
  </div>
</template>

<script setup>
import { useTequityGames } from '@/composables/tequityGames/useTequityGames';

const { minesResult, } = useTequityGames();
const gameStore = useGamesStore();
const { assetUrl, gameFairnessData, } = storeToRefs(gameStore);

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
  mines: {
    type: Number,
    default: 1,
  },
});

const gameName = computed(() => (gameFairnessData.value?.name || 'mines').toLowerCase());
const bombUrl = computed(() => `${assetUrl.value}/${gameName.value}/assets/history/mines/bomb.svg`);
const gemUrl = computed(() => `${assetUrl.value}/${gameName.value}/assets/history/mines/gem.svg`);
const gameResult = computed(() => minesResult(props.result, props.mines));
</script>
